import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import { Dashboard } from './components';
import './App.css';

const Fragment = React.Fragment;

function App() {
  return (
    <div className="App">
      <Fragment>
        <Dashboard />
      </Fragment>
    </div>
  );
}

export default App;
