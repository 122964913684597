import React, {  Component } from 'react';
import { Scrollama, Step } from 'react-scrollama';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { styled } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { grey } from '@material-ui/core/colors';
import _ from 'lodash';
import {PieChart, Pie, Tooltip, ResponsiveContainer, LabelList} from 'recharts';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cityData from './cityData.js';
import chroma from 'chroma-js';
import * as d3 from 'd3';
import { animateScroll as scroll } from 'react-scroll'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Police Costs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

function pad(num, size) {
  var s = num+"";
  while (s.length < size) s = "‏‏‎ ‎" + s;
  return s;
}

Math.radians = function(degrees) {
  return degrees * Math.PI / 180;
};

function setTooltip(val){
  if(val===null){
    d3.select('#DotTooltip').style("display","none")
  } else {
    d3.select("#DotTooltip")
      .style("display","block")
      .style("left",`${val[4]+10}px`)
      .style("top",`${val[5]}px`)
      .text(`${val[1]}: ${Math.round(val[2]/val[3]*100)}% ($${val[2].toLocaleString()})`)
  }
}
const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
;

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      // Purple and green play nicely together.
      main: grey[900],
    },
    secondary: {
      // This is green.A700 as hex.
      main: grey[100],
    },
  },
  typography: {
    h1: {
      textAlign:'left'
    },
    h2: {
      textAlign: 'left'
    },
    p: {
      textAlign: "left"
    }
  }
});

const avePct = _.meanBy(cityData, function(o) { return o.policeCost/o.totalBudget; });

const Title = styled(Typography)({
  textAlign: 'left',
  padding: '0 0 20px 0',
  fontWeight: 'bold'
});

const CityTitle = styled(Typography)({
  fontFamily: `'Playfair Display', serif`,
  fontSize:"4vw",
  lineHeight:'2',
  '@media (max-width:1200px)': {
    fontSize:"6vw"
  },
  '@media (max-width:960px)': {
    fontSize:"8vw"
  },
  '@media (max-width:600px)': {
    fontSize:"10vw"
  }
});
const DetailH1 = styled(Typography)({
  fontFamily: `'IBM Plex Mono', monospace`,
  fontSize:"2.4vw",
  lineHeight:'1.4',
  '@media (max-width:960px)': {
    fontSize:"5vw"
  },
  '@media (max-width:600px)': {
    fontSize:"7vw"
  }
})

const DetailH2 = styled(Typography)({
  fontFamily: `'Playfair Display', serif`,
  fontSize:"1.5vw",
  lineHeight:'1.5',
  '@media (max-width:960px)': {
    fontSize:"3vw"
  },
  '@media (max-width:600px)': {
    fontSize:"5vw"
  }
})

const CityPct = styled(Typography)({
  fontFamily: `'IBM Plex Mono', monospace`,
  fontSize:"30vw",
  transform:'translateY(-50%)',
  opacity:0.03,
  pointerEvents:'none',
  letterSpacing:'2vw',
  fontWeight: '700'
});

const CityPctHidden = styled(Typography)({
  fontFamily: `'IBM Plex Mono', monospace`,
  fontSize:"30vw",
  transform:'translateY(-50%)',
  pointerEvents:'none',
  letterSpacing:'2vw',
  fontWeight: '700'
});

const PctGrid = styled(Grid)({
  zIndex:'3',
  position:'fixed',
  width:'100%',
  height:'50%',
  left:'-2%',
  top:'55%',
  pointerEvents:'none'
})

const GradientOverlay = styled(Grid)({
  position: 'fixed',
  width: '100%',
  height: '100%',
  left:'0',
  top:'0',
  zIndex:'2',
  background: `rgb(20,20,18)`,
  background: `linear-gradient(0deg, rgba(20,20,18,.9) 50%, rgba(20,20,18,0) 100%)`,
  pointerEvents: 'none'
});

const ReadMoreButton = styled(Button)({
  width:'80vw',
  position:'fixed',
  bottom:'5vh',
  left:'50%',
  transform:'translateX(-50%)',
  zIndex:'3',
  textTransform:'none',
  fontWeight:'400',
  fontFamily: `'IBM Plex Mono', monospace`,
  fontSize:"2vw",
  '@media (max-width:1600px)': {
    fontSize:"2vw"
  },
  '@media (max-width:1200px)': {
    fontSize:"4vw"
  },
  '@media (max-width:960px)': {
    fontSize:"5vw"
  },
  '@media (max-width:600px)': {
    fontSize:"6vw"
  }
});

const SideButton = styled(Button)({
  background:"black",
  margin:"5px 0"
})

class Dashboard extends Component {

  constructor(props) {
    super(props);
    const { classes } = props;
    this.state = {
      activeCity: null,
      drawerOpen:false,
      selectedCity: [],
      currCTA: `of the general fund budget allocated to police →`,
      currMeasure: 0,
      sideShow: -1
    }; 
  }

  DrawDotArray(){
    let data = cityData;
    var margin = {top: 0, right: 0, bottom: 0, left: 0},
      width = 40 - margin.left - margin.right,
      height = document.getElementById("dotArray").clientHeight - margin.top - margin.bottom;

    var svg = d3.select("#dotArray")
        .html("")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");

    // Add X axis
    var y = d3.scaleLinear()
      .domain([0, 50])
      .range([height, 0 ]);
    svg.append("g")
      .style("color","white")
      // .call(d3.axisLeft(y));

    // Add dots
    svg.append('g')
    .selectAll("dot")
    .data(data)
    .enter()
    .append("circle")
      .attr("cy", function (d) { return y(d.policeCost/d.totalBudget*100); } )
      .attr("id",function (d) { return `dot${d.idx}`; })
      .attr("r",3)
      .style("stroke","rgba(0,0,0,0)")
      .style("stroke-width","20")
      .attr("class","infoDot")
      .on("mouseover", function(d){setTooltip([d.idx,d.city,d.policeCost,d.totalBudget,d3.event.pageX,d3.event.pageY])})
      .on("mouseout", function(){setTooltip(null)});

      var svg = d3.select("#dotArray")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
    .append("g")
      .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
  }
  
  componentDidMount() {
    var isScrolling;

    this.DrawDotArray()
    document.getElementById("main").addEventListener('click', (event) => {
      this.setState({
        drawerOpen:false,
        sideShow:-1
      })
    })
    window.addEventListener('resize', this.DrawDotArray);
    
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.setState({
        iosUser: true
      })
    }
    // window.addEventListener('touchstart', (event) => {
    //   this.setState({
    //     touchUser: true
    //   })
    // });

    // // Listen for scroll events
    // window.addEventListener('scroll', () => {    
      
    //   // Setup isScrolling variable
    //   var isScrolling;
    //   this.hideCitySearch()

    //   // Clear our timeout throughout the scroll
    //   window.clearTimeout( isScrolling );

    //   // Set a timeout to run after scrolling ends
    //   isScrolling = () => setTimeout(function() {

    //     // Run the callback
    //     this.showCitySearch()

    //   }, 300)

    // }, false)

    // window.addEventListener('scroll', (event) => {
    //   this.setState({
    //     hideSearch:true
    //   })
    //   // Clear our timeout throughout the scroll
    //   window.clearTimeout( isScrolling );

    //   // Set a timeout to run after scrolling ends
    //   isScrolling = () => setTimeout(function() {
    //     console.log('stopped')
    //     this.setState({
    //       hideSearch:false
    //     })

    //   }, 1)
    
    // }, false);
  }

  sortArr(a, b) {
    // Use toUpperCase() to ignore character casing
    const A = a.y
    const B = b.y
  
    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }
    return comparison;
  }

  onStepEnter = ({ data }) => {
    this.setState({
      activeCity:data
    })
    let tempidx = cityData[_.findIndex(cityData, function(o) { return o.city == data; })].idx
    
    d3.selectAll(".infoDot").attr("r", "3").style("opacity", "0.5")
    d3.select(`#dot${tempidx}`).attr('r',"7").style("opacity","0.75")
  };

  selectCity(city){
    this.setState({
      selectedCity:[city]
    });
    if (!this.state.iosUser){
      let containerDiv = document.getElementById("CityDetailContainer")
      disableBodyScroll(containerDiv);
    }
  };

  handleDrawerOpen(){
    this.setState({
      drawerOpen:!this.state.drawerOpen,
      sideShow:-1
    })
  }

  returnToList(){
    this.setState({
      selectedCity:[]
    })
    if (!this.state.iosUser){
      let containerDiv = document.getElementById("CityDetailContainer")
      enableBodyScroll(containerDiv);
    }
  }
  
  sideShow(idx){
    this.setState({
      sideShow:idx
    })
  }
  
  handleCitySearch = (event, newValue) => {
    scroll.scrollTo(this.refs[`${newValue}_list`].offsetTop-window.innerHeight*.09, 
      {
        duration:250,
        smooth: "easeOut",
        delay:0
      }
    )
  }
  
  hideCitySearch = () =>{
    this.setState({
      hideSearch: false
    });
  }

  showCitySearch = () =>{
    this.setState({
      hideSearch: true
    });
  }

  render(){

    var CityList = cityData.map(elem => {return (
            <Step data={elem.city}>
              <Grid item xs={12}>
                <CityTitle className={this.state.activeCity === elem.city ? `cityTitle activeCity` : `cityTitle`} ref={`${elem.city}_list`}>
                  {elem.city}
                </CityTitle>
              </Grid>
            </Step>
      )}
    )

    var PctList = cityData.map(elem => {return ( 
           <PctGrid item xs={12}>
             <CityPct className={`CityFigure`}>
               {pad(Math.round(elem.policeCost/elem.totalBudget*100),2)}%
             </CityPct>
           </PctGrid>
      )}
    )

    var PctListHidden = cityData.map(elem => {return ( 
      <PctGrid item xs={12}>
        <CityPctHidden className={this.state.activeCity === elem.city ? `CityFigure activeCity` : `CityFigure`}>
          {pad(Math.round(elem.policeCost/elem.totalBudget*100),2)}%
        </CityPctHidden>
      </PctGrid>
      )}
    )

    var HealthDollarList = cityData.map(elem => {return ( 
      <PctGrid item xs={12}>
        <CityPct className={`CityFigure`}>
          ${pad(Math.round(elem.policeCost/elem.healthBudget))}
        </CityPct>
      </PctGrid>
    )}
    )

    var HealthDollarListHidden = cityData.map(elem => {return ( 
    <PctGrid item xs={12}>
      <CityPctHidden className={this.state.activeCity === elem.city ? `CityFigure activeCity` : `CityFigure`}>
        ${pad(Math.round(elem.policeCost/elem.healthBudget))}
      </CityPctHidden>
    </PctGrid>
    )}
    )

    var CityDetail = this.state.selectedCity.map(elem => {
      if (elem.length > 0){
        var cityIndex = _.findIndex(cityData, function(o) { return o.city == elem; });
        var currCityData = cityData[cityIndex]
        var pieData = []
        var cityPct = currCityData.policeCost/currCityData.totalBudget

        let colors = chroma.scale(['#ffffff',"#000000"]).mode('lch').colors(currCityData.costs.length)

        for (let i=0; i<currCityData.costs.length;++i) {
          let rowColor = currCityData.costs[i] === currCityData.policeCost ? "#0099ff" : colors[i]
          pieData.push({x: currCityData.objects[i], y: currCityData.costs[i], fill:rowColor})
        }
        
        pieData = _.orderBy(pieData, ['y'],['asc']); // Use Lodash to sort array by 'name'
        
        var CustomTooltip = ({ active, payload, label }) => {
          if (active) {
            return (
              <div className="custom-tooltip">
                <p className="label">{`${payload[0].payload.x}: $${payload[0].payload.y.toLocaleString()}`}</p>
              </div>
            );
          }
          return null;
        };

        var CustomLabel = (props) => {
          if (props.y === currCityData.policeCost) {
            
            let angle = (props.viewBox.startAngle+props.viewBox.endAngle)/2
            let rad = Math.radians(angle % 360);
            let shiftX = Math.sin(rad);
            let shiftY = Math.cos(rad);
            console.log(`${shiftX}${shiftY}`)
            return (//style={{transform: "translate(0, 65%)", fontWeight:"bold"}}
              <g>
                <text x={props.viewBox.cx} y={props.viewBox.cy} fill="#fff" textAnchor="middle" dominantBaseline="middle" style={{transform:`translate(${shiftY*props.viewBox.outerRadius*.9}px,${shiftX*props.viewBox.outerRadius/2*-1}px)`}}>
                  {props.x}: ${props.y.toLocaleString()}
                </text>
              </g>
            )
          } else {
            return null
          }
        };

        var comparisonCost = cityPct > avePct ? `${Math.abs(Math.round((((cityPct)-avePct))*100))}% more of their general budget than the average in our data (${Math.round(avePct*100)}%). We cannot bear this cost.` : `${Math.abs(Math.round(((cityPct)-avePct)*100))}% less of their general budget than the average in our data (${Math.round(avePct*100)}%). However, this cost still remains too high.`

        return (
            <Grid container spacing={3} className={this.state.selectedCity.length === 0 ? 'InfoContainer hiddenContainer': 'InfoContainer'}>
              <Grid item xs={12} md={7} style={{textAlign:"left"}}>
                <DetailH1 style={{display:"inline"}}>
                  {currCityData.city} allocates ${currCityData.policeCost.toLocaleString()} ({Math.round(cityPct*100)}%) of its ${currCityData.totalBudget.toLocaleString()} general fund budget to police. This cost is ${Math.round(currCityData.policeCost/currCityData.pop)} per capita each year. More is spent through corrections and other related departments.
                </DetailH1>
                <a style={{display:"inline"}} target="_blank" href={`https://twitter.com/intent/tweet?text=${currCityData.city.replace(' ','%20')}%20spends%20${Math.round(cityPct*100)}%25%20of%20its%20general%20fund%20budget%20on%20local%20police.%20That's%20$${Math.round(currCityData.policeCost/currCityData.pop)}%20per%20capita,%20per%20year.%20Learn%20more%20here:%20https://www.policecost.us/${currCityData.shortCode}/%20@PoliceCostUs`}><img alt="Share this page on twitter" src={`${process.env.PUBLIC_URL}/Twitter_Logo_Blue.svg`} className="socialImg"></img></a>
                <a style={{display:"inline"}} target="_blank" href={`https://www.facebook.com/dialog/share?href=http://www.policecost.us&app_id=375097183453482`}><img src={`${process.env.PUBLIC_URL}/facebook.svg`} className="socialImg"></img></a>
                
                <DetailH2 className={"serifH2"}>
                  <br></br><br></br>
                  Here's what you can do:
                </DetailH2>
                <ul>
                  {
                    currCityData.cta.map(item => {
                      if (item.text.length > 3) {
                          return (
                            <li><DetailH2 className={"serifH2"}><a href={`${item.link}`} target="_blank">{item.text}</a></DetailH2></li>
                          )
                        }
                      }
                    )
                  }
                </ul>
              </Grid>
              <Grid item xs={12} md={5} style={{width:"100%",height:"100vh"}} className={"chartGridColumn"}>
                <DetailH2 className={"serifH2"}>
                  {currCityData.city} General Fund Budget
                </DetailH2>
                <ResponsiveContainer height="85%">
                <PieChart>
                  <Pie dataKey="y" isAnimationActive={false} data={pieData} fill="#8884d8" stroke="#141412" strokeWeight="2">                  
                    <LabelList dataKey="x" content={<CustomLabel />} />
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
                <Typography>Source: <a href={currCityData.budgetSource} target="_blank">{currCityData.city} {currCityData.budgetType} General Fund</a></Typography>
              </Grid>
              <Button style={{position:"fixed",left:"50%",transform:"translateX(-50%)",bottom:"40px", background:"#0099ff",fontSize:'14px'}} onClick={() => this.returnToList()}>return to list</Button>
            </Grid>
        )
    }
  })


    return (
      <div>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen.bind(this)}
              edge="start"
              className={this.state.drawerOpen ? "closeDrawer" : "openDrawer"}
            > </IconButton>       
              <Drawer
                variant="persistent"
                anchor="left"
                open={this.state.drawerOpen}
                style={{zIndex:'10',padding:'20px',background:'rgba(0,0,0,0)'}}
                className={"drawer"}
              >
                <SideButton onClick={() => this.sideShow(0)}>Data</SideButton>
                <SideButton onClick={() => this.sideShow(1)}>About</SideButton>
                <SideButton onClick={() => this.sideShow(2)}>Contact</SideButton>
                <SideButton onClick={() => this.sideShow(3)}>Resources</SideButton>
              </Drawer>
              <div className={this.state.sideShow===0 ? "infoBox active" : "infoBox"} style={{top:"25px"}}>
                Our data has been gathered exclusively from the General Fund in each municipality’s approved 2020 or proposed 2021 budget. Every budget referenced is available for download.
                <br></br><br></br>A <span style={{color:"#0099ff"}}>General Fund</span> is a municipality’s primary source of funding and is mainly composed of taxpayer dollars. While a General Fund does not make up the entirety of a municipality’s budget* the money in a General Fund is mostly unrestricted and discretionary— meaning, elected officials have the flexibility to decide how much each department receives. Elected officials have an obligation to use these funds in ways that honor their community’s needs. 
                <br></br><br></br>*other funds, known as “dedicated funds,” may include an Enterprise Fund, an Education Fund, etc.
              </div>
              <div className={this.state.sideShow===1 ? "infoBox active" : "infoBox"} style={{top:"75px"}}>
                Black Lives Matter.
                <br></br><br></br>The intent of this site is to make clear, concise, and accessible the allocation of money to police departments in each state’s largest city and eventually, cities across the country. Nationally, municipal police departments disproportionately consume taxpayer dollars, directly underwriting the brutalization and deaths of Black and Brown People. By highlighting the egregious amounts of money allocated to police, we hope this site will help empower residents to advocate for defunding the police in their community and for reinvesting in community and public health resources.
                <br></br><br></br>Defunding and abolishing the police is just one pillar in the movement to guarantee the safety, equity, and liberty of all Black, Indigenous, and People of Color living in the United States; divesting from systemic white supremacy is a life-long endeavor. This site strives to be in service of sustained action. 
                <br></br><br></br>PoliceCost.Us is made by <a href="https://twitter.com/dchalpern" target="_blank" className="lightLink">Dylan Halpern</a> and <a href="https://twitter.com/katcaribeaux" target="_blank" className="lightLink">Kat Caribeaux.</a>
              </div>
              <div className={this.state.sideShow===2 ? "infoBox active" : "infoBox"} style={{top:"125px"}}>
                We welcome and are grateful for all questions, comments, and feedback.
                <br></br><br></br>Please contact us at <a href="mailto:info@PoliceCost.Us" target="_blank" className="lightLink">info@policecost.us</a>
              </div>
              <div className={this.state.sideShow===3 ? "infoBox active" : "infoBox"} style={{top:"175px"}}>
                <b>PoliceCost.Us</b> was sparked by the ACLU of Massachusetts' <a href="https://data.aclum.org/2020/06/05/unpacking-the-boston-police-budget/" target="_blank" className="lightLink"><i>Unpacking the Boston Police Budget"</i></a> authored by <a href="https://twitter.com/lauren_marietta" target="_blank" className="lightLink">Lauren Chambers</a>. 
              </div>
          <main id="main" style={this.state.sideShow != -1 ? {opacity:"0.1"}:{}}>
            <Container maxWidth="xl">  
              <GradientOverlay />
                <Grid container spacing={0} style={{zIndex:'1', padding:'30vh 0 80vh 0'}}>
                  <Scrollama onStepEnter={this.onStepEnter} offset='0.2'>
                    {CityList}
                  </Scrollama>
                </Grid>
              <div id="dotArray" ref="dotArray"></div>
              <div id="DotTooltip"></div>
              <Grid container spacing={0} style={{pointEvents:'none', transition:'250ms all', filter:'blur(10px)', position:'fixed', top:'55%',left:'0', zIndex:'3', opacity:(this.state.currMeasure===0 ? '1': '0')}} className={this.state.activeCity != null ? 'activatedPct': ''}>{PctList}</Grid>
              <Grid container spacing={0} style={{pointEvents:'none', position:'fixed', top:'55%',left:'0', zIndex:'3', opacity:(this.state.currMeasure===0 ? '1': '0')}}>{PctListHidden}</Grid>
              <ReadMoreButton className={(this.state.selectedCity.length > 0)||(this.state.activeCity === null) ? 'hiddenButton': 'activeButton'} onClick={() => this.selectCity(this.state.activeCity)}>{this.state.currCTA}</ReadMoreButton>
              <div id={"CityDetailContainer"}>
                {CityDetail}
              </div>
              <div className={this.state.sideShow===0 ? "infoBox active" : "infoBox"} style={{top:"25px"}}>
              Our data has been gathered exclusively from the General Fund in each municipality’s approved 2020 or proposed 2021 budget. Every budget we reference is available to download on a municipality’s individual details page.<br></br><br></br>
              A <span style={{color:"#0099ff"}}>General Fund</span> is a municipality’s primary source of funding and is mainly composed of taxpayer dollars. While a General Fund does not make up the entirety of a municipality’s budget* the money in a General Fund is mostly discretionary— meaning, elected officials have the power to decide how this money is used. Elected officials have an obligation to use these funds in ways that reflect their community’s needs.<br></br><br></br> 
              *other funds, known as “dedicated funds,” may include an enterprise fund, an education fund, etc.

              </div>
              <div className={this.state.sideShow===1 ? "infoBox active" : "infoBox"} style={{top:"75px"}}>
              Black Lives Matter.<br></br><br></br>
              The intent of this site is to make clear, concise, and accessible the exact allocation of money to police departments in each state’s largest city. Across the country, police departments disproportionately consume taxpayer dollars, directly underwriting the brutalization and deaths of Black and Brown people. By highlighting the egregious amounts of money allocated to police, we hope that this site will help empower residents to advocate for defunding the police in their community, and reinvesting in community and public health programs.<br></br><br></br>
              Defunding and abolishing the police is just one pillar in the movement to guarantee the safety, equity, and liberty of all Black people, Indigenous people, and People of Color living in the United States; divesting from systemic white supremacy is a life-long endeavor. This site strives to be in service of sustained action. <br></br><br></br>
              policecost.us is made by <a href="https://twitter.com/dchalpern" target="_blank" className="lightLink">Dylan Halpern</a> and <a href="https://twitter.com/katcaribeaux" target="_blank" className="lightLink">Kat Caribeaux.</a>

              </div>
              <div className={this.state.sideShow===2 ? "infoBox active" : "infoBox"} style={{top:"120px"}}>
                We welcome and are grateful for all questions, comments, and feedback.<br></br><br></br>
                Please contact us at <a href="mailto:info@policecost.us" target="_blank" className="lightLink">info@policecost.us</a>
              </div>
              
              {/* <div style={{zIndex:2, position:'fixed',top:'10%',width:'80%',left:"10%"}}>
              <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={cityData.map(o=>o.city)}
                  onChange={this.handleCitySearch}
                  onClick={() => this.setState({hideSearch:false})}
                  style={{opacity: (this.state.hideSearch ? 0.25 : 1), transition: '250ms all'}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search or scroll to your city"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  
                  )}
                />
              </div> */}

              {/* <div id={`toggleButtons`}>                
              <Button onClick={() => this.setState({currMeasure:0, currCTA:`of general budget allocated to police →`})}>
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 20 20" className={this.state.currMeasure === 0 ? "active" : ""}>
                      <g>
                        <path d="M5.9,11.4h3.1l-4.2,8.3H1.7L5.9,11.4z M6.3,10.3c-1.4,0-2.5-0.5-3.2-1.4C2.3,8,1.9,6.8,1.9,5.2S2.3,2.3,3,1.4
                          C3.8,0.5,4.9,0,6.3,0s2.5,0.5,3.2,1.4c0.8,0.9,1.1,2.2,1.1,3.8S10.2,8,9.5,8.9C8.7,9.8,7.6,10.3,6.3,10.3z M6.3,7.9
                          c0.5,0,0.9-0.2,1.1-0.6c0.2-0.4,0.3-0.9,0.3-1.6V4.5c0-0.6-0.1-1.2-0.3-1.6C7.2,2.6,6.8,2.4,6.3,2.4C5.7,2.4,5.4,2.6,5.1,3
                          C4.9,3.4,4.8,3.9,4.8,4.5v1.2c0,0.6,0.1,1.2,0.3,1.6C5.4,7.7,5.7,7.9,6.3,7.9z M13.8,20c-1.4,0-2.5-0.5-3.2-1.4
                          c-0.8-0.9-1.1-2.2-1.1-3.8s0.4-2.9,1.1-3.8c0.8-0.9,1.8-1.4,3.2-1.4s2.5,0.5,3.2,1.4c0.8,0.9,1.1,2.2,1.1,3.8s-0.4,2.9-1.1,3.8
                          C16.2,19.5,15.2,20,13.8,20z M15.1,0.3h3.1l-4.2,8.3h-3.1L15.1,0.3z M13.8,17.6c0.5,0,0.9-0.2,1.1-0.6c0.2-0.4,0.3-0.9,0.3-1.6
                          v-1.2c0-0.6-0.1-1.2-0.3-1.6c-0.2-0.4-0.6-0.6-1.1-0.6c-0.5,0-0.9,0.2-1.1,0.6c-0.2,0.4-0.3,0.9-0.3,1.6v1.2c0,0.6,0.1,1.2,0.3,1.6
                          C12.9,17.4,13.2,17.6,13.8,17.6z"/>
                      </g>
                  </svg>
                </Button>
                <Button onClick={() => this.setState({currMeasure:1, currCTA:`for every $1 spent on public health →`})}>
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 20 20" className={this.state.currMeasure === 1 ? "active" : ""}>
                    <g>
                      <path d="M17,4h-2.1c-0.5-2.3-2.5-4-4.9-4S5.6,1.7,5.1,4H3C1.3,4,0,5.3,0,7v10c0,1.6,1.3,3,3,3h14c1.7,0,3-1.4,3-3V7
                        C20,5.3,18.7,4,17,4z M10,2c1.3,0,2.4,0.8,2.8,2H7.2C7.6,2.8,8.7,2,10,2z M13,13h-2v2c0,0.5-0.5,1-1,1s-1-0.5-1-1v-2H7
                        c-0.5,0-1-0.5-1-1s0.5-1,1-1h2V9c0-0.5,0.5-1,1-1s1,0.5,1,1v2h2c0.5,0,1,0.5,1,1S13.5,13,13,13z"/>
                    </g>
                  </svg>
                </Button>
                <Button>
                  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="10" cy="10" r="10"/>
                  </svg>
                </Button>
              </div> */}
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </main>
        </ThemeProvider>
      </div>

    );
  }
}

export default Dashboard;